import styled from 'styled-components'
import { Image } from 'components/v2/Image'
import { Column } from 'components/v2/Grid'
import { Text } from 'components/v2/Text'

export const StyledImage = styled(Image)`
  position: absolute;
  right: 0;

  width: 655px;
  height: 526px;

  align-self: center;
  margin-right: 64px;

  @media (max-width: 1024px) {
    top: 200px;
    margin-right: auto;
  }
`

export const StyledColumn = styled(Column)`
  width: 100%;
  max-width: 709px;

  @media (max-width: 1024px) {
    padding: 20px;
  }
`

export const StyledLink = styled(Text)`
  text-decoration: underline;
  color: ${({ theme: { colors } }) => colors.primary} !important;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
`

export const ModalContainer = styled(Column)`
  ${({ theme: { colors, shadows } }) => `
    max-width: 558px;
    max-height: 500px;
    width: 100%;
    height: 100%;
    background: ${colors.white};
    box-shadow: ${shadows.boxShadow};
    border-radius: 6px;
    padding: 20px 30px 30px;
  `}
`

export const ToastWrapper = styled.div`
  background: white;
  max-width: 400px;
  padding: 16px;
  border-radius: 4px;
  position: absolute;
  bottom: 165px;
  right: 50px;
  z-index: 2;
`

export const IconContainer = styled.div`
  cursor: pointer;
  height: 16px;
  width: 16px;
`
